import Flatpickr from 'stimulus-flatpickr'

import { english } from "flatpickr/dist/l10n/default.js";

export default class extends Flatpickr {
  locales = {
    en: english
  };

  // all flatpickr hooks are available as callbacks in your Stimulus controller
  change(selectedDates, dateStr, instance) {
    // console.log('the callback returns the selected dates', selectedDates)
    // console.log('but returns it also as a string', dateStr)
    // console.log('and the flatpickr instance', instance)
  }
}