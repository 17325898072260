import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ['entries']

  initialize() {
    this.url = this.element.dataset.url;
    this.loadEntries();
  }

  isInViewport  (elem) {
    var bounding = elem.getBoundingClientRect();
    return (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  scroll() {
    if (this.isInViewport(this.entriesTarget.lastElementChild)) {
      this.loadEntries("first=" + this.entriesTarget.firstElementChild.dataset.id + "&last=" + this.entriesTarget.lastElementChild.dataset.id)
    }
  }

  loadEntries(data = '') {
    if (this.loading) { return }
    this.loading = true

    Rails.ajax({
      type: 'GET',
      url: this.url,
      dataType: 'json',
      data: data,
      success: (data) => {
        this.setUnreadCount(data.unread)
        this.entriesTarget.insertAdjacentHTML('beforeend', data.entries)
        this.loading = false
      }
    })
  }

  setUnreadCount(count) {
    this.removeBadge(document.getElementById('avatarBtn'))
    this.removeBadge(document.getElementById('notifications-tab'))

    if (count > 0) {
      this.createBadge(document.getElementById('avatarBtn'), count, 'badge-light')
      this.createBadge(document.getElementById('notifications-tab'), count, 'badge-secondary')
    }
  }

  removeBadge(elem) {
    let badge = elem.getElementsByClassName('badge')
    if (badge.length == 0) { return }
    badge[0].remove();
  }

  createBadge(parent, count, klass) {
    let badge = document.createElement('span')
    badge.classList.add('badge')
    badge.classList.add('badge-pill')
    badge.classList.add(klass)
    badge.textContent = count
    parent.appendChild(badge)
  }
}