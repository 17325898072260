import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []
  
  get gameController() {
    const elem = document.getElementsByClassName('game');
    return this.application.getControllerForElementAndIdentifier(elem[0], "game");
  }

  endPlaying() {
    this.gameController.endPlaying()
  }
}