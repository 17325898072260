// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import JQuery from 'jquery';
window.$ = window.JQuery = JQuery;

import 'rails/custom_ujs'
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("bootstrap")
require('bootstrap-select')

import "controllers"
import '../stylesheets/application.scss'

var ready;

ready = function() {
  // Cookie Seetings
  if ($('.cookie_settings').length > 0) {
    $.ajax({
      method: 'get',
      url: '/cookies'
    })
  }

  if ($('.guest-login').length > 0) {
    let gameId = document.getElementsByClassName('game')[0].dataset.gameId
    $.ajax({
      method: 'get',
      url: '/guests/new',
      data: { game_id: gameId }
    })
  }

  // Toggle Sidebar
  $('#navbarMain .overlay').on('click', function(event) {
    $('#navbarMain').toggleClass('show');
    event.preventDefault;
    return false;
  });

  $('[data-toggle="tooltip"]').tooltip()

  // TODO: check if needed
  // $('#notifications-tab').on('click', function(event) {
  //   $('.open-sidebar .badge').hide();
  //   $('#notifications-tab .badge').hide();
  //   $.ajax({
  //     method: 'post',
  //     url: this.dataset.url
  //   })
  // });
};

$(document).on('turbolinks:load', ready);
