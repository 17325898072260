import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ ]

  get gameController() {
    const elem = document.getElementsByClassName('game');
    return this.application.getControllerForElementAndIdentifier(elem[0], "game");
  }

  get storyController() {
    const elem = document.getElementsByClassName('story');
    return this.application.getControllerForElementAndIdentifier(elem[0], "story");
  }

  get pickedCardVariantId() {
    return this.data.get('card-variant-id')
  }

  get pickedCard() {
    let cardElem = document.querySelector("[data-card-variant-id='" + this.pickedCardVariantId + "']")
    if (cardElem) {
      cardElem = cardElem.cloneNode(true)
      delete cardElem.dataset.action
      delete cardElem.dataset.cardVariantId
    }
    return cardElem
  }

  get participantId() {
    return this.data.get('participant-id')
  }

  connect() {
    if (this.storyController == null) { return; }

    if ((this.storyController.state == 'estimating') && (this.pickedCardVariantId == null)) {
      this.showPlayingCards()
    } else {
      this.hidePlayingCards()
    }
  }

  showPlayingCards(story_id) {
    delete this.element.dataset.estimationCardVariantId
    document.getElementById('estimations').classList.add('pick')
  }

  hidePlayingCards() {
    document.getElementById('estimations').classList.remove('pick')
  }

  pickCard(event) {
    let activeStory = document.getElementsByClassName('active-story')
    
    if ((!!this.storyController && this.storyController.state == 'estimating') ||
        (activeStory.length > 0 && activeStory[0].dataset.storyState == 'estimating')) { 
      this.data.set('card-variant-id', event.target.dataset.cardVariantId)
      this.gameController.gameChannel.perform('pick_card', {
        story_id: !!this.storyController ? this.storyController.id : activeStory[0].dataset.storyId, 
        card_variant_id: this.pickedCardVariantId
      })
      this.hidePlayingCards()
      this.insertPickedCard()
    }
  }

  insertPickedCard() {
    let participantElem = document.getElementById('participant-' + this.participantId)
    let replaceElem = participantElem.getElementsByClassName('playing-card')[0]
    replaceElem.parentNode.replaceChild(this.pickedCard, replaceElem)
  }
}