import Rails from "@rails/ujs"

Rails.confirm = function(message, element) {
  let $element = $(element)
  
  $("#layerModal .modal-title").html(element.dataset.title)
  $("#layerModal .modal-body").html(message)

  $("#layerModal .modal-footer").show();
  $('#layerModal .modal-dialog').removeClass('modal-sm')
  $('#layerModal').modal('show')

  $('#layerModal .btn-confirm').click(function(event) {
    event.preventDefault()
    $('#layerModal').modal('hide')

    let old = Rails.confirm

    Rails.confirm = function() { return true }
    $element.get(0).click();
    Rails.confirm = old;
  })

  return false
}
Rails.start()