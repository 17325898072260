import { Controller } from "stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  static targets = [ "content" ];

  get storyController() {
    const elem = document.getElementsByClassName('story');
    return this.application.getControllerForElementAndIdentifier(elem[0], "story");
  }

  get estimationController() {
    const elem = document.getElementsByClassName('estimation');
    return this.application.getControllerForElementAndIdentifier(elem[0], "estimation");
  }

  get id() {
    return this.data.get('id')
  } 

  get state() {
    return this.data.get('state')
  }

  get activeStory() {
    return document.getElementsByClassName('active-story')[0]
  }

  connect() {
    if (this.element.classList.contains('admin') || this.element.classList.contains('member') || this.element.classList.contains('guest')) {
      this.connectWebsocket();
    }
    this.checkNoMoreStories();
  }

  disconnect() {
    this.gameChannel.unsubscribe();
  }

  connectWebsocket() {
    let thisController = this;

    /* Websockets */
    thisController.gameChannel = consumer.subscriptions.create({ 
      channel: (this.element.classList.contains('admin') ? 'GameAdminChannel' : 'GameMemberChannel'), 
      game_id: this.data.get('id')
    }, {
      received(data) {
        // console.log(data);
        if (data.hasOwnProperty('method')) {
          eval("thisController." + data.method + "(data)");
        } else {
          // TODO: missing method
        }
        // https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/eval
      },
    });
  }

  startPlaying() {
    this.gameChannel.perform('start_playing', {game_id: this.data.get('id')});
  }

  endPlaying() {
    this.gameChannel.perform('end_playing', {game_id: this.data.get('id')});
  }

  finishedGame() {
    window.location.href = this.element.dataset['report'];
  }

  relayGame(data) {
    this.data.set('state', data.state)
    let headlineElem = document.getElementById('headline');
    if (headlineElem) {
      headlineElem.innerHTML = data.game
    }
  }

  // relayStory(data) {
  //   let storiesElem = document.getElementById('stories');

  //   if (storiesElem) {
  //     storiesElem.innerHTML = data.story
  //   }
  //   if (data.pick_card) {
  //     this.estimationController.showPlayingCards(data.story_id)
  //   } else {
  //     this.estimationController.hidePlayingCards()
  //   }
  // }

  relayCreatedStory(data) {
    let storiesElem = document.getElementById('stories');

    // Story List
    let listItem = document.getElementById('story-new');
    let newListItem = new DOMParser().parseFromString(data.list_item, 'text/html').body.firstChild;
    if (listItem) {
      listItem.parentNode.replaceChild(newListItem, listItem);
    } else {
      let list = storiesElem.getElementsByClassName('list-group')[0];
      list.appendChild(newListItem);
    }
  }

  relayUpdatedStory(data) {
    let storiesElem = document.getElementById('stories');
    let fstElem = storiesElem.firstElementChild;
    let nextStates = ['skipped', 'finished'];
    
    let listItem = document.getElementById('story-' + data.story_id);
    if (listItem) {
      // Story List
      let newListItem = new DOMParser().parseFromString(data.list_item, 'text/html').body.firstChild;
      listItem.parentNode.replaceChild(newListItem, listItem);
    } else if ((fstElem.dataset.storyId == data.story_id) || nextStates.includes(fstElem.dataset.storyState)) {
      // Story View
      let newItem = new DOMParser().parseFromString(data.story, 'text/html').body.firstChild;
      storiesElem.replaceChild(newItem, storiesElem.firstElementChild);
    }

    if (data.pick_card) {
      this.estimationController.showPlayingCards(data.story_id)
    } else {
      this.estimationController.hidePlayingCards()
    }
  }

  relayDeletedStory(data) {
    let storiesElem = document.getElementById('stories');

    let listItem = document.getElementById('story-' + data.story_id)
    if (listItem) {
      // Story List
      listItem.remove()
    } else if (storiesElem.firstElementChild.dataset.storyId == data.story_id) {
      // Story View
      let back = storiesElem.firstElementChild.getElementsByClassName('back-link')[0];
      back.click();
    }
  }

  relayFinishedStories(data) {
    let storiesElem = document.getElementById('stories');

    let newItem = new DOMParser().parseFromString(data.story, 'text/html').body.firstChild;
    storiesElem.replaceChild(newItem, storiesElem.firstElementChild);

    this.checkNoMoreStories();
  }

  // Only run this method if starting a game
  // It will show the transfered story view
  relayConsiderStory(data) {
    let storiesElem = document.getElementById('stories');

    if (storiesElem) {
      storiesElem.innerHTML = data.story
    }
  }

  relayStateChangedStory(data) {
    this.relayUpdatedStory(data);
  }

  relayParticipant(data) {
    let participantElem = document.getElementById('participant-' + data.participant_id)
    let newElem = new DOMParser().parseFromString(data.participant, 'text/html').body.firstChild;

    if ((this.storyController != null) && 
        (this.storyController.state == 'estimating') && 
        (this.estimationController.participantId == data.participant_id)) {
          
      let pickedCardElem = this.estimationController.pickedCard
      if (pickedCardElem) {
        let replaceElem = newElem.getElementsByClassName('playing-card')[0]
        replaceElem.parentNode.replaceChild(pickedCardElem, replaceElem)
      }
    }

    if (participantElem) {
      participantElem.parentNode.replaceChild(newElem, participantElem);
    } else {
      document.getElementById('participants').appendChild(newElem);
    }
  }

  relayEstimation(data) {
    let estimationElem = document.getElementById('estimations');

    if (estimationElem) {
      estimationElem.innerHTML = data.story
    }
  }

  checkNoMoreStories() {
    if (this.element.classList.contains('admin') && (this.state == 'playing') && !this.activeStory) {
      $.ajax({
        method: 'get',
        url: "/games/" + this.id + "/stories/no_more"
      })
    }
  }
}
