import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['planVersion', 'paymentPeriod', 'price', 'invoiceRun']
  
  initialize() {
    this.priceInfo = JSON.parse(this.planVersionTarget.dataset.info)
    this.invoiceText = JSON.parse(this.invoiceRunTarget.dataset.text)
    this.calculatePrice()
  }

  calculatePrice() {
    let price = this.priceInfo[this.planVersionTarget.value]
    let period = this.paymentPeriodTarget.value
    if (period == 'yearly') {
      price = price * 10
    }
    this.priceTarget.textContent = 'Total: ' + (price/100) + ' €'
    if (price > 0) {
      this.invoiceRunTarget.textContent = this.invoiceText[period]
    } else {
      this.invoiceRunTarget.textContent = this.invoiceText['zero']
    }
  }
}