import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ ]
  points = null

  get gameController() {
    const elem = document.getElementsByClassName('game');
    return this.application.getControllerForElementAndIdentifier(elem[0], "game");
  }

  get estimationController() {
    const elem = document.getElementsByClassName('estimation');
    return this.application.getControllerForElementAndIdentifier(elem[0], "estimation");
  }

  get id() {
    return this.data.get('id')
  } 

  get state() {
    return this.data.get('state')
  }

  skipStory() {
    this.estimationController.hidePlayingCards()
    this.gameController.gameChannel.perform('skip_story', {story_id: this.id})
  }

  startEstimation() {
    this.estimationController.showPlayingCards()
    this.gameController.gameChannel.perform('start_estimation', {story_id: this.id})
  }

  showEstimations() {
    this.estimationController.hidePlayingCards()
    this.gameController.gameChannel.perform('show_estimations', {story_id: this.id})
  }

  restartEstimation() {
    this.estimationController.showPlayingCards()
    this.gameController.gameChannel.perform('restart_estimation', {story_id: this.id})
  }

  setPoints(event) {
    this.points = event.target.innerText
    if (!isNaN(this.points)) {
      this.points = parseFloat(this.points)
    }

    let pointList = document.getElementById('points')
    for (let button of pointList.getElementsByClassName('btn')) {
      button.classList.remove('btn-primary')
      button.classList.add('btn-light')
    }

    event.target.classList.remove('btn-light')
    event.target.classList.add('btn-primary')
    document.getElementsByClassName('save-points')[0].disabled = false
  }

  setEstimation() {
    this.gameController.gameChannel.perform('set_estimation', {story_id: this.id, points: this.points})
  }

  
}
